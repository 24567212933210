/*
 *	-- Components --
 *	Scripts concatenated and placed before document closing </body>
 */

var thirst = thirst || {};

// import('components/site-nav.js');
// import('components/newsletter-form.js');
// import('components/map.js');
// import('components/parallax.js');


$(document).ready(function () {

    thirst.siteNav.init();
	//thirst.newsletterForm.init();
    thirst.map.init();
    thirst.parallax.init();

});
